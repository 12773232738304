<script lang="ts">
	// Imports
	import { route } from '../stores'

	// Components
	import Logo from './Logo.svelte'
	// Props
	export let navItmes: { id: number; text: string; link: string }[]
</script>

<style lang="scss">
	@import '../styles/variables';

	footer {
		margin-top: 9rem;
		padding: 3rem 0;
		background-color: $dark-1;
	}
	.top-footer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		@include media('>=medium') {
			flex-direction: row;
		}

		.footer-nav-links {
			display: flex;
			flex-direction: row;
			margin-top: 2rem;
			@include media('>=medium') {
				margin-top: 0;
			}
			li {
				margin-right: 24px;
				&:last-of-type {
					margin-right: 0;
				}

				a {
					font-size: 1.125rem;
				}
			}
		}
	}
	.middle-footer {
		display: flex;
		flex-direction: row;
		margin: 2rem auto;
		max-width: 32rem;
		a {
			flex: 1;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.bottom-footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		span {
			text-align: center;
			&:last-of-type {
				margin: 0;
			}
		}

		@include media('>=medium') {
			flex-direction: row;
			justify-content: space-between;
			span {
				margin: 0;
			}
		}
	}
</style>

<footer>
	<div class="container">
		<div class="top-footer">
			<Logo bird={false} />
			<ul class="footer-nav-links">
				{#each navItmes as item}
					<li><a class:active={$route === item.link} href={item.link}>{item.text}</a></li>
				{/each}
			</ul>
		</div>
		<div class="middle-footer">
			<a href="https://www.nationalnotary.org/info/choose-nna/" target="_blank">
				<img
					src="./images/nna_member_badge.png"
					alt="Proud Memeber of the National Notary Association"
					title="Proud Memeber of the National Notary Association" />
			</a>
			<a href="https://www.nationalnotary.org/info/choose-nsa" target="_blank">
				<img
					src="./images/nsa_member_badge.png"
					alt="Certified Notary Signing Agent"
					title="Certified Notary Signing Agent" />
			</a>
			<a href="https://www.nationalnotary.org/info/choose-nsa" target="_blank">
				<img
					src="./images/nsa_trained_logo.png"
					alt="Trained Notary Signing Agent"
					title="Trained Notary Signing Agent" />
			</a>
		</div>
		<div class="bottom-footer">
			<span>Copyright © 2020</span>
			<span>Designed & Developed by <a href="#">River City Web Designs</a></span>
		</div>
	</div>
</footer>
