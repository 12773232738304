<script lang="ts">
	import { ChevronDownIcon } from 'svelte-feather-icons'

	export let question = ''

	let expanded = false
	let answer
	$: {
		if (answer) {
			if (expanded) {
				answer.style.maxHeight = answer.scrollHeight + 'px'
			} else {
				answer.style.maxHeight = '0'
			}
		}
	}
</script>

<style lang="scss">
	@import '../styles/variables';

	.question {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		transition: all $timing-function;

		margin: 1rem 0;
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}

		h2 {
			font-weight: 400;
			color: $light-1;
			margin: 0;
		}

		.icon {
			height: 2.25rem;
			width: 2.25rem;
			transition: all $timing-function;
			margin-left: 2rem;

			&.rotated {
				transform: rotate(180deg);
			}
		}

		&.expanded {
			h2 {
				color: $primary;
			}
		}
	}

	.answer {
		transition: all $timing-function;
		margin-left: 1rem;
		margin-right: 1rem;
		max-height: 0;
		overflow: hidden;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
</style>

<div class="faq">
	<div class="question" class:expanded on:click={() => (expanded = !expanded)}>
		<h2>{question}</h2>
		<div class="icon" class:rotated={expanded}>
			<ChevronDownIcon size="36" class="red-icon" />
		</div>
	</div>
	<div class="answer" class:expanded bind:this={answer}>
		<slot />
	</div>
</div>
