<script lang="ts">
	import router from 'page'

	// Views
	import Home from './views/Home.svelte'
	import Contact from './views/Contact.svelte'
	import Services from './views/Services.svelte'
	import About from './views/About.svelte'
	import FAQ from './views/FAQ.svelte'

	import ErrorPage from './views/ErrorPage.svelte'

	// Components
	import Footer from './components/Footer.svelte'
	import Nav from './components/Nav.svelte'
	let uid = 1
	const navItems = [
		{
			id: uid++,
			text: 'Contact',
			link: '/contact',
		},
		{
			id: uid++,
			text: 'Services',
			link: '/services',
		},
		{
			id: uid++,
			text: 'About',
			link: '/about',
		},
		{
			id: uid++,
			text: 'FAQ',
			link: '/faq',
		},
	]

	// Setup routing
	import { route } from './stores'
	let page

	router('/', () => updateRoute(Home, '/'))
	router('/contact', () => updateRoute(Contact, '/contact'))
	router('/services', () => updateRoute(Services, '/services'))
	router('/about', () => updateRoute(About, '/about'))
	router('/faq', () => updateRoute(FAQ, '/faq'))
	router('/*', () => updateRoute(ErrorPage, ''))

	function updateRoute(newPage, newRoute) {
		page = newPage
		route.set(newRoute)
	}

	router.start()
</script>

<style lang="scss" global>
	@import './styles/global.scss';
</style>

<Nav navItmes={navItems} />
<!-- Dynamic Route Here -->
<svelte:component this="{page}"/>
<Footer navItmes={navItems} />
