<script lang="ts">
	import { fade } from 'svelte/transition'
	import { CheckIcon } from 'svelte-feather-icons'

	const topFields = [
		{
			label: 'First Name',
			name: 'firstName',
			type: 'text',
			error: '',
			required: true,
			cols: 3,
		},
		{
			label: 'Last Name',
			name: 'lastName',
			type: 'text',
			error: '',
			required: true,
			cols: 3,
		},
		{
			label: 'Email',
			name: 'email',
			type: 'email',
			error: '',
			required: true,
			cols: 3,
		},
		{
			label: 'Phone Number',
			name: 'phone',
			type: 'tel',
			error: '',
			required: true,
			cols: 3,
		},
		{
			label: 'Address Line 1',
			name: 'address1',
			type: 'text',
			error: '',
			required: true,
			cols: 3,
		},
		{
			label: 'Address Line 2',
			name: 'address2',
			type: 'text',
			error: '',
			required: false,
			cols: 3,
		},
		{
			label: 'City',
			name: 'city',
			type: 'text',
			error: '',
			required: true,
			cols: 1,
		},
		{
			label: 'State',
			name: 'state',
			type: 'text',
			error: '',
			required: true,
			cols: 1,
		},
		{
			label: 'Zipcode',
			name: 'zipcode',
			type: 'text',
			error: '',
			required: true,
			cols: 1,
		},
	]

	let notaryPublicChecked: any = false
	let signingAgentChecked = false
	let additionalInfo = {
		text: '',
		error: '',
	}
</script>

<style lang="scss">
	@import '../styles/variables.scss';

	.contact-form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0 1rem;
	}

	.contact-form-upper {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		@include media('>=small') {
			grid-template-columns: repeat(3, 1fr);
		}
		column-gap: 1rem;
	}

	img {
		margin: 3rem 0;
	}

	.form-field {
		&.form-field--cols-1 {
			grid-column: span 1;
		}
		&.form-field--cols-2 {
			grid-column: span 1;
			@include media('>=small') {
				grid-column: span 2;
			}
		}
		&.form-field--cols-3 {
			grid-column: span 1;
			@include media('>=small') {
				grid-column: span 3;
			}
		}

		position: relative;

		label {
			display: block;
			position: absolute;
			color: $dark-4;
			font-family: 'Yanone Kaffesatz';
			font-size: 1.125rem;
			font-weight: 800;
			left: 1rem;
			bottom: 0.625rem;
			line-height: 1.25rem;
			transition: all $timing-function;
		}
		input:not([type='checkbox']) {
			border: none;
			box-shadow: none;
			border-radius: 0.5rem;
			height: 2.5rem;
			padding: 0 1rem;
			width: 100%;
			background-color: $dark-1;
			font-family: 'Montserrat';
			font-weight: 600;
			color: $light-1;
			caret-color: $primary;
			margin-top: 3.1875rem;
			transition: all $timing-function;
			&::placeholder {
				color: transparent;
				opacity: 0;
			}
		}
		.error-text {
			position: absolute;
			right: 0.5rem;
			bottom: 3rem;
			color: $primary;
			font-family: 'Yanone Kaffesatz';
			font-size: 1rem;
			font-weight: 800;
			line-height: 1.25rem;
			margin: 0;
		}
		input:not([type='checkbox']):focus-within,
		input:not([type='checkbox']):not(:placeholder-shown) {
			& ~ label {
				bottom: 3rem;
				left: 0.5rem;
				color: $primary;
			}
			& + .error-text + label {
				color: $light-1;
			}
		}

		input:not([type='checkbox']):focus-within {
			background-color: $dark-2;
			box-shadow: $shadow-2-colored;
			outline: none;
		}

		textarea {
			width: 100%;
			background-color: $dark-1;
			border: none;
			border-radius: 0.5rem;
			padding: 0.5rem 1rem;
			color: $light-1;
			font-family: 'Montserrat';
			font-size: 1rem;
			font-weight: 600;
			caret-color: $primary;
			transition: all $timing-function;

			&::placeholder {
				opacity: 0;
				color: transparent;
			}

			& ~ label {
				top: 0.625rem;
			}

			& + .error-text {
				top: -1.75rem;
			}

			&:focus-within {
				background-color: $dark-2;
				box-shadow: $shadow-2-colored;
			}

			&:not(:placeholder-shown),
			&:focus-within {
				& ~ label {
					top: -1.75rem;
					color: $primary;
				}

				& + .error-text + label {
					color: $light-1;
				}
			}
		}
	}

	.lower-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.form-field {
			width: 100%;
		}
	}

	.checkboxes {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
		margin: 3rem 0;

		label {
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding-left: 1.5rem;
			height: 100%;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		.form-field--checkbox {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		input {
			opacity: 0;
			position: absolute;
			&:checked {
				& + label {
					color: $light-1;
				}
			}
		}
		.checkmark {
			margin: 0;
			width: 2.5rem;
			height: 2.5rem;
			background-color: $dark-1;
			border-radius: 0.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	input[type='submit'] {
		margin-top: 3rem;
	}
</style>

<form
	action="/#thank-you"
	data-netlify="true"
	name="quote-form"
	method="POST"
	class="contact-form"
	id="quote-form">
	<input type="hidden" name="form-name" value="quote-form" />
	<h2>Contact Info</h2>
	<div class="contact-form-upper">
		{#each topFields as field}
			<div class="form-field form-field--cols-{field.cols}">
				<input
					type={field.type}
					id={field.name}
					name={field.name}
					placeholder={field.label}
					required={field.required} />
				{#if field.error}
					<p class="error-text">{field.error}</p>
				{/if}
				<label for={field.name}>{field.label}</label>
			</div>
		{/each}
	</div>
	<img src="./images/Accent.svg" alt="Separator" />
	<h2>Request Info</h2>
	<div class="lower-form">
		<div class="checkboxes">
			<div class="form-field form-field--checkbox">
				<span
					class="checkmark"
					on:click={() => {
						notaryPublicChecked = !notaryPublicChecked
					}}>
					{#if notaryPublicChecked}
						<div transition:fade={{ duration: 200 }}>
							<CheckIcon class="red-icon" size="24" />
						</div>
					{/if}
				</span>
				<input
					type="checkbox"
					name="notaryPublic"
					tabindex="0"
					id="notaryPublic"
					bind:checked={notaryPublicChecked} />
				<label for="notaryPublic">Notary Public</label>
			</div>
			<div class="form-field form-field--checkbox">
				<span
					class="checkmark"
					on:click={() => {
						signingAgentChecked = !signingAgentChecked
					}}>
					{#if signingAgentChecked}
						<div transition:fade={{ duration: 200 }}>
							<CheckIcon class="red-icon" size="24" />
						</div>
					{/if}
				</span>
				<input
					type="checkbox"
					name="signingAgent"
					id="signingAgent"
					tabindex="0"
					bind:checked={signingAgentChecked} />
				<label for="signingAgent">Signing Agent</label>
			</div>
		</div>
		<div class="form-field">
			<textarea
				required
				name="additional"
				id="additional"
				rows="10"
				placeholder="Additional Info" />
			{#if additionalInfo.error}
				<p class="error-text">{additionalInfo.error}</p>
			{/if}
			<label for="additional">Additional Info</label>
		</div>
	</div>
	<input
		type="submit"
		name="submit"
		id="submit"
		value="Submit Quote Request"
		class="btn btn-primary" />
</form>
