<script lang="ts">
	import Visibility from '../components/Visibility.svelte'
	import ContactForm from '../components/ContactForm.svelte'
	import { PhoneIcon, MailIcon } from 'svelte-feather-icons'
	import { onMount } from 'svelte'
	import { fly, fade } from 'svelte/transition'

	$: handshakeSrc =
		screenWidth >= 1024 ? './images/handshake_nobackground.svg' : './images/handshake.svg'
	$: documentSrc =
		screenWidth >= 1024 ? './images/document_nobackground.svg' : './images/document.svg'

	let screenWidth = window.outerWidth
	window.addEventListener('resize', (e: Event) => {
		screenWidth = window.outerWidth
	})

	let showThanks = false
	onMount(() => {
		if (window.location.hash === '#thank-you') {
			showThanks = true
		}
	})
</script>

<style lang="scss">
	@import '../styles/variables';

	.thanks-message {
		position: fixed;
		z-index: 50;
		bottom: 2rem;
		right: 1rem;
		width: 18.75rem;
		background-color: $primary;
		font-weight: 600;
		padding: 1rem 2rem;
		border-radius: 1rem;
		transition: all $timing-function;
		cursor: pointer;
		box-shadow: $shadow-2-colored;

		@include media('>=small') {
			top: 8rem;
			bottom: unset;
		}
		@include media('>=1440px') {
			right: 4rem;
		}

		&:hover {
			box-shadow: $shadow-4-colored;
		}

		&::after {
			content: 'X';
			position: absolute;
			top: 1rem;
			right: 1rem;
			font-family: 'Montserrat';
			color: $light-1;
		}
	}

	.hero {
		position: relative;
		margin-top: 3rem;
		display: flex;
		flex-direction: column;

		@include media('>=large') {
			flex-direction: row;
			min-height: 80vh;
			align-items: center;
		}

		.content {
			flex: 4;
			z-index: 10;
			width: 100%;

			h1 {
				margin-bottom: 0.5rem;
			}
			p {
				margin: 0;
				width: 100%;
				@include media('>=large') {
					width: 100%;
				}
			}
			.buttons {
				margin-top: 2rem;
				a + a {
					margin-top: 1rem;
				}
			}
			h2 {
				margin-top: 2rem;
				margin-left: 1rem;
			}
			ul {
				margin-left: 4rem;
				li {
					color: $light-1;
					font-family: 'Montserrat';
					font-size: 1.125rem;
					margin-bottom: 1rem;
					padding-left: 7px;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}

		&.content--right {
			.content {
				@include media('>=small') {
					align-self: flex-end;
					width: 50%;
					p {
						width: 100%;
					}
				}
			}
		}

		img {
			flex: 6;
			position: relative;
			width: calc(100% + 32px);
			height: 100%;
			@include media('>=small') {
				width: 100%;
			}
		}
		img:last-child {
			right: 1rem;
			top: -1.25rem;
			@include media('>=small') {
				top: -10rem;
				right: -1rem;
			}
			@include media('>=large') {
				top: 0rem;
			}
		}
		img:first-child {
			order: 2;
			left: -1rem;
			top: -1.25rem;
			@include media('>=small') {
				position: absolute;
				width: 50%;
				left: 0;
				transform: scale(1.3);
				align-self: flex-start;
			}
			@include media('>=large') {
				position: relative;
				order: 0;
				top: 0rem;
				transform: scale(0.7);
				left: -2rem;
				align-self: center;
			}
		}

		&.hero--centered {
			@include media('>=large') {
				min-height: unset;
			}
			.centered-content {
				text-align: center;
			}
		}
	}

	.road-image {
		overflow: hidden;
		width: 100%;
		position: relative;

		.car {
			max-width: 800px;
			width: 100%;
			position: relative;
			top: 3rem;
			left: -100%;
			float: right;
			&.animate {
				animation: car-drive 1s ease-in-out forwards;
			}
		}

		.road {
			min-width: 100%;
		}
	}

	@keyframes car-drive {
		from {
			left: -100%;
		}
		to {
			left: 0;
		}
	}

	.contact-form-container {
		margin-top: 3rem;
		display: grid;
		grid-template-columns: 1fr;
		@include media('>=large') {
			margin-top: 8rem;
			grid-template-columns: repeat(2, 1fr);
		}

		.quote-text {
			display: flex;
			flex-direction: column;
			align-items: center;
			h1 {
				margin-bottom: 0.5rem;
			}
			img {
				margin: 3rem 0;
			}
			.subheading {
				margin: 0;
			}
			.info-text {
				text-decoration: none;
				margin-bottom: 2rem;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				span {
					margin: 0 0 0 1.5rem;
					transition: all $timing-function;
				}

				&:hover {
					text-decoration: underline;
					span {
						color: $primary-light;
					}
				}

				@include media('>=medium') {
					align-self: flex-start;
				}
			}

			@include media('>=medium') {
				margin: 0 2rem 0 0;
			}
		}
	}
</style>

<main role="main">
	{#if showThanks}
		<div
			in:fly={{ x: 200, duration: 500, delay: 500 }}
			out:fade={{ duration: 200 }}
			class="thanks-message"
			on:click={() => (showThanks = false)}>
			<p>Thank you for submitting your request. We will be in contact with you shortly!</p>
		</div>
	{/if}
	<div class="container">
		<section class="hero">
			<div class="content">
				<h1>
					<span class="highlight">Mobile</span>
					Signing Agent &amp;
					<span class="highlight">Notary</span>
					Public
				</h1>
				<p>Mobile & flexible to fit your needs across the Greater Richmond Area</p>
				<div class="buttons">
					<a href="/contact#quote-form" class="btn btn-primary">Request a Quote</a>
					<a href="/services" class="btn btn-primary--ghost">Learn More</a>
				</div>
			</div>
			<img src={handshakeSrc} alt="Handshake with cardinal" />
		</section>
		<section class="hero content--right">
			<img src={documentSrc} alt="Document with Cardinal Stamp" />
			<div class="content">
				<h1><span class="highlight">Documents</span> We Work With</h1>
				<p>
					As a mobile notary service and signing agent service, we have the ability to sign
					many different types of documents
				</p>
				<h2>Mobile Notary</h2>
				<ul class="styled-list">
					<li>Wills</li>
					<li>Powers of Attorney</li>
					<li>Advanced Medican Directive</li>
					<li>Separation Agreements</li>
					<li>Financial Documents</li>
					<li>And Many More...</li>
				</ul>
				<h2>Signing Agent</h2>
				<ul class="styled-list">
					<li>Refinance Loans</li>
					<li>Purchase Loans</li>
					<li>1st & 2nd Mortgages</li>
					<li>Home Equity Loans</li>
					<li>Commercial Loans</li>
					<li>And Many More...</li>
				</ul>
				<div class="buttons">
					<a href="/contact#quote-form" class="btn btn-primary">Request a Quote</a>
					<a href="/services" class="btn btn-primary--ghost">Learn More</a>
				</div>
			</div>
		</section>
		<section class="hero hero--centered">
			<div class="content">
				<div class="centered-content">
					<h1>We Come To <span class="highlight">You</span></h1>
					<p>
						Not matter the convienient time or place, we come to you to make notarizing your
						documents a breeze
					</p>
				</div>
				<h2>Advantages</h2>
				<ul class="styled-list">
					<li>Available to meet at any time or place for any notary service</li>
					<li>Flexibble and timely scheduling to meet your needs</li>
					<li>Honest and efficient performance of notary services</li>
				</ul>
				<div class="buttons">
					<a href="/contact#quote-form" class="btn btn-primary">Request a Quote</a>
				</div>
			</div>
		</section>
	</div>
	<div class="road-image">
		<Visibility bottom={16} target={50} steps={10} let:percent let:unobserve>
			<img
				use:unobserve
				class:animate={percent > 50}
				class="car"
				src="./images/car.svg"
				alt="Cardinal in a car" />
		</Visibility>
		<img class="road" src="./images/Road.svg" alt="The road the cardinal is driving on" />
	</div>
	<div class="container">
		<div class="contact-form-container">
			<div class="quote-text">
				<h1>Get a <span class="highlight">Quote</span></h1>
				<p class="subheading">
					Fill out this form and we will contact you shortly with a quote on the services you
					request. We typically respong within 24 hours of receiving your request.
				</p>
				<img src="./images/Accent.svg" alt="Separator" />
				<h2>Or, Contact Us At</h2>
				<a href="tel:4342604740" class="info-text">
					<PhoneIcon class="red-icon" size="24" />
					<span>(434) 260-4740</span>
				</a>
				<a href="mailto:gracekaiser@hotmail.com" class="info-text">
					<MailIcon class="red-icon" size="24" />
					<span>gracekaiser@hotmail.com</span>
				</a>
			</div>
			<ContactForm />
		</div>
	</div>
</main>
