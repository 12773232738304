<script lang="ts">
	export let bird: boolean = true
</script>

<style lang="scss">
	@import '../styles/variables';

	.logo {
		text-decoration: none;
		transition: unset;
		display: flex;
		flex-direction: row;
		justify-content: start;
		align-items: center;
		&:hover {
			.black {
				color: $light-1;
			}
			.red {
				color: $primary;
			}
		}

		.black,
		.red {
			display: flex;
			flex-direction: row;
			justify-content: start;
			align-items: center;
			padding: 10px 1rem 4px;
			span {
				font-size: 2.5rem;
				font-family: 'Yanone Kaffesatz';
				margin: 0 0 0 0.75rem;
				&:first-child {
					margin: 0;
				}
			}
		}
		.black {
			background-color: #000000;
			border-radius: 1rem;
		}
		.red span {
			color: $primary;
			@include media('<=360px') {
				display: none;
			}
		}
	}
</style>

<a href="/" class="logo">
	<div class="black">
		{#if bird}<img src="./images/Bird.svg" alt="Bird Logo of Cardinal Notary" />{/if}
		<span>Cardinal</span>
	</div>
	<div class="red"><span>Notary</span></div>
</a>
