<script lang="ts">
	import { fade } from 'svelte/transition'
	import { route } from '../stores'

	// Components
	import Logo from './Logo.svelte'

	// props
	export let navItmes: { id: number; text: string; link: string }[]
	let open = false
	$: {
		$route
		open = false
	}
	let y

	let birds = []
	navItmes.forEach(item => {
		birds = [...birds, false]
	})
</script>

<style lang="scss">
	@import '../styles/variables';

	header {
		top: -1px;
		position: sticky;
		z-index: 200;
		.container {
			padding: 2rem 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		transition: all $timing-function;

		&.raised {
			background-color: rgba($dark-0, 0.9);
			box-shadow: $shadow-2;
		}
	}
	a {
		text-decoration: none;
	}

	.nav {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	// SVG stuff
	svg {
		transition: all $timing-function;

		line {
			stroke: $primary-dark;
			stroke-width: 4px;
			transition: all $timing-function;
		}
		#top {
			transform-origin: 100% 4px;
		}
		#bottom {
			transform-origin: 100% 28px;
		}
		#middle {
			transform-origin: 100% 18px;
		}

		&.open {
			transform: scale(0.8);
			#top {
				transform: translate(-4.5px, -0.7px) rotate(-45deg);
			}
			#bottom {
				transform: translate(-4.5px, 0.7px) rotate(45deg);
			}
			#middle {
				transform: scaleX(0);
			}
		}
	}
	button {
		border: none !important;
		background: unset;
		padding: 0;
		cursor: pointer;
		height: 40px;
		z-index: 110;
		@include media('>=medium') {
			display: none;
		}
	}

	// mobile nav
	.mobile-nav {
		@include media('>=medium') {
			display: none;
		}

		z-index: 100;
		position: fixed;
		width: 0;
		overflow: hidden;
		top: 0;
		right: 0;
		height: 100%;
		background-color: rgba($dark-0, 0.9);
		transition: width $timing-function;
		box-shadow: $shadow-2;
		max-width: 100vw;

		&.open {
			width: 300px;
		}

		ul {
			margin-top: 120px;
			li {
				padding: 0.5rem 1rem;
				a {
					font-size: 1.25rem;

					&.active {
						color: $primary-light;
					}
				}
			}
		}
	}

	// Desktop nav
	.desktop-nav {
		display: none;
		@include media('>=medium') {
			display: flex;
		}

		flex-direction: row;
		justify-content: flex-end;
		height: 100%;

		li {
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
			&:last-of-type a {
				margin-right: 0;
			}
		}

		a {
			font-size: 2.5rem;
			font-family: 'Yanone Kaffesatz';
			margin-right: 2rem;

			&.active {
				color: $primary-light;
			}
		}
	}

	// Nav Bird
	.nav-bird {
		display: none;
		@include media('>=medium') {
			display: block;
		}

		position: absolute;
		width: 19px;
		height: 24px;
		top: -12px;
		left: -9px;
	}
</style>

<!-- For scroll position -->
<svelte:window bind:scrollY={y} />
<header class:raised={y > 0}>
	<div class="container">
		<Logo />
		<nav class="nav">
			<button
				class="mobile-nav-button"
				on:click={() => {
					open = !open
				}}>
				<svg width="40" height="32" class:open>
					<line id="top" x1="40" y1="2" x2="0" y2="2" />
					<line id="middle" x1="40" y1="16" x2="0" y2="16" />
					<line id="bottom" x1="40" y1="30" x2="0" y2="30" />
				</svg>
			</button>
			<ul class="desktop-nav">
				{#each navItmes as item}
					<li
						on:mouseover={() => (birds[item.id] = true)}
						on:mouseout={() => (birds[item.id] = false)}>
						{#if birds[item.id]}
							<img
								src="./images/Bird.svg"
								alt="Nav Bird for hover effect"
								transition:fade
								class="nav-bird" />
						{/if}
						<a class:active={$route === item.link} href={item.link}>{item.text}</a>
					</li>
				{/each}
			</ul>
		</nav>
	</div>
</header>

<nav class="mobile-nav" class:open>
	<ul>
		{#each navItmes as item}
			<li><a class:active={$route === item.link} href={item.link}>{item.text}</a></li>
		{/each}
	</ul>
</nav>
