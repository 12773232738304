<script lang="ts">
	import Hero from '../components/Hero.svelte'
	import Callout from '../components/Callout.svelte'

	let mn_id = 0
	let sa_id = 0
	const services = {
		mobileNotary: [
			{
				id: mn_id++,
				heading: 'Legal Documents',
				expanded: false,
				bullets: [
					'Wills',
					'Powers of Attorney',
					'Financial Power of Attorney',
					'Advance Medical Directive',
				],
			},
			{
				id: mn_id++,
				heading: 'Family Documents',
				expanded: false,
				bullets: ['Paternity', 'Adoption', 'Separation Agreements', 'Divorce', 'School Forms'],
			},
			{
				id: mn_id++,
				heading: 'Real Estate Documents',
				expanded: false,
				bullets: [
					'Contact Amendments',
					'Hmoeowners Association By-Laws',
					'Proof of Residency',
					'Property Lease',
				],
			},
			{
				id: mn_id++,
				heading: 'Financial Docs',
				expanded: false,
				bullets: ['Financial Documents', 'Automobile Title Transfers', 'Bills of Sale'],
			},
			{
				id: mn_id++,
				heading: 'Miscellaneous Documents',
				expanded: false,
				bullets: ['Travel Documents', 'Insurance Claims'],
			},
			{
				id: mn_id++,
				heading: 'Court Documents',
				expanded: false,
				bullets: ['Depositions', 'Affidavits', 'Petitions'],
			},
		],
		signingAgent: [
			{
				id: sa_id++,
				heading: 'Loans',
				expanded: false,
				bullets: [
					'Refinance Loans',
					'Purchase Loans',
					'Commercial Loans',
					'Construction Loans',
					'Reverse Mortgage Loans',
					'Loan Modifications',
					'Home Equity Lines of Credit',
				],
			},
			{
				id: sa_id++,
				heading: 'Other',
				expanded: false,
				bullets: ['Structured Settlements', 'Debt Consolidations', '1st & 2nd Mortgages'],
			},
		],
	}

	function expandService(services, service: HTMLElement, id) {
		services[id].expanded = !services[id].expanded

		const bullets: HTMLElement = service.querySelector('.service-bullets')
		if (services[id].expanded) {
			bullets.style.maxHeight = bullets.scrollHeight + 'px'
			bullets.style.marginTop = '2rem'
		} else {
			bullets.style.maxHeight = '0'
			bullets.style.marginTop = '0'
		}
	}

	function expandMNService(event) {
		const target: HTMLElement = event.target //Works here since we're always handling from a button
		const service = findService(target)
		const id = parseInt(service.getAttribute('data-id'))

		expandService(services.mobileNotary, service, id)
	}

	function expandSAService(event) {
		const target: HTMLElement = event.target //Works here since we're always handling from a button
		const service = findService(target)
		const id = parseInt(service.getAttribute('data-id'))

		expandService(services.signingAgent, service, id)
	}

	function findService(element: HTMLElement): HTMLElement {
		let node = element
		while (!node.classList.contains('service')) {
			node = node.parentElement
		}

		return node
	}
</script>

<style lang="scss">
	@import '../styles/variables';

	.services-grid {
		display: grid;
		grid-template-columns: 1fr;

		@include media('>=medium') {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.services-section {
		display: flex;
		flex-direction: column;
		align-items: center;

		h2 {
			font-weight: 400;
		}

		@include media('<medium') {
			&:not(:first-of-type) {
				margin-top: 3rem;
			}
		}
	}

	img {
		margin: 3rem 0;

		&:last-of-type {
			display: none;
		}
	}

	.services,
	.service {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.service-header {
		width: 10.75rem;
		padding: 1.5rem;
		border-radius: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $dark-1;
		border: none;
		cursor: pointer;
		transition: all $timing-function;

		&:hover {
			background-color: $dark-2;
			box-shadow: $shadow-2 !important;
			h3 {
				color: $primary;
			}
		}

		&.expanded {
			background-color: $dark-2;
			box-shadow: $shadow-1;
			margin-bottom: 2rem;
		}

		&:active {
			box-shadow: none !important;
		}

		h3 {
			font-size: 32px;
			font-weight: 400;
			margin: 0;
			transition: all $timing-function;
		}
	}

	.service-bullets {
		max-height: 0;
		overflow: hidden;
		transition: all $timing-function;

		display: grid;
		grid-template-columns: repeat(1, 1fr);
		list-style-image: url('../images/list_small_dot.svg');
		list-style-type: disc;

		padding-left: 1rem;

		li {
			margin-bottom: 2rem;
			font-family: 'Montserrat';
			color: $light-1;
			font-size: 1.125rem;
		}

		@include media('>=small') {
			grid-template-columns: repeat(2, 1fr);
			gap: 0 2rem;
		}
	}
</style>

<main role="main" class="container">
	<Hero redText="Services" subheading="See the different services we offer" />
	<div class="services-grid">
		<div class="services-section mobile-notary-services">
			<h2>Mobile Notary</h2>
			<div class="services">
				{#each services.mobileNotary as service}
					<div class="service" data-id={service.id}>
						<button
							class="service-header"
							class:expanded={service.expanded}
							on:click={expandMNService}>
							<h3>{service.heading}</h3>
						</button>
						<ul class="service-bullets" class:expanded={service.expanded}>
							{#each service.bullets as bullet}
								<li>{bullet}</li>
							{/each}
						</ul>
					</div>
					<img src="./images/Accent.svg" alt="null" />
				{/each}
			</div>
		</div>
		<div class="services-section signing-agent-services">
			<h2>Signing Agent</h2>
			<div class="services">
				{#each services.signingAgent as service}
					<div class="service" data-id={service.id}>
						<button
							class="service-header"
							class:expanded={service.expanded}
							on:click={expandSAService}>
							<h3>{service.heading}</h3>
						</button>
						<ul class="service-bullets" class:expanded={service.expanded}>
							{#each service.bullets as bullet}
								<li>{bullet}</li>
							{/each}
						</ul>
					</div>
					<img src="./images/Accent.svg" alt="null" />
				{/each}
			</div>
		</div>
	</div>
	<Callout />
</main>
